import React from 'react';
import './Weather.css';
import ReactWeather from 'react-open-weather-widget';


function Weather() {

  return <React.Fragment>

    <h2>Het weer de komende paar dagen:</h2>
    <link rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/weather-icons/2.0.9/css/weather-icons.min.css"
      type="text/css" />

    <ReactWeather
      forecast="5days"
      apikey="92bee5fcc5be55a3a81b639dd00e854c"
      type="city"
      city="Rotterdam"
    />

  </React.Fragment>
}

export default Weather;
