import React from 'react';
import IsHetAlTijd from './IsHetAlTijd';
import Weather from './Weather';

function Voorpagina(props) {

    return (
        <React.Fragment>
            <header className="App-header">
                <h1>
                    Is het al tijd voor de buurborrel?
                </h1>
            </header>

            <div className="App-components">
                {/* party day: 5 = friday */}
                <IsHetAlTijd
                    now={props.now}
                    loadingBarMax={604800}
                    partyStartDay={5}
                    partyStartHour={17}
                    partyStartMinute={0}
                    textEvent={'Het is al tijd voor de buurborrel :)'} />
                <Weather />
            </div>
        </React.Fragment>
    )
}

export default Voorpagina;
