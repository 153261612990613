import './App.css';
import React from 'react';
import ShowNotes from './components/ShowNotes';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Voorpagina from './components/Voorpagina';

function App() {

  const [now, setNow] = React.useState(new Date());

  React.useEffect(() => {
    setInterval(
      () => {
        setNow(new Date());
      }
      , 1000);
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path path="/" element={<Voorpagina now={now} />}/>
          <Route path="/shownotes" element={<ShowNotes />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
