import React from 'react';
import './ShowNotes.css';
import {
    Link
} from "react-router-dom";

function ShowNotes(props) {

    return (
        <div className="shownotes">

            <Link to={'/shownotes'}><h2>Shownotes</h2></Link>

            <ul>
            <li>2021-09-17
                    <ul>
                        <li>De duurzaamste koffiecupjes zijn geen koffiecupjes</li>
                        <li>Een normaal mens wordt er rustig van</li>
                        <li>Kat coacht mensen die paard coachen</li>
                        <li>Wat je vindt mag je roken</li>
                    </ul>
                </li>


            <li>2021-08-07
                    <ul>
                        <li>Vlaamse hondentherapeut met goede achternaam</li>
                        <li>Pastel-lime kleur van de dag</li>
                        <li>Help Remco en Elise en spuug in Flips eten</li>
                        <li>Juiste soep in het verkeerde seizoen</li>
                        <li>Contra-vlammetjesoffensief wederom ingezet</li>
                    </ul>
                </li>

            <li>2021-07-16
                    <ul>
                        <li>10 September buurborrel bij Saskia</li>
                        <li>Dakterras borrel bij Astrid</li>
                        <li>Pro en anti-vlammetjes</li>
                        <li>Vaginisme of squirten Op een kartonnen bed </li>
                        <li>Prostaatmassage</li>
                        <li>De grond is de nieuwe bank</li>
                        <li>Jacky O in tha house</li>
                    </ul>
                </li>


            <li>2021-07-09
                    <ul>
                        <li>Knak- en sauzenfestijn</li>
                        <li>Showorkest</li>
                        <li>Bernard's parkiet deed ook nooit wat</li>
                        <li>Slippers met piercings</li>
                        <li>Astrid was weer eens gemeen</li>
                        <li>Faustina spuit kaas</li>
                        <li>#boompjesborrel</li>
                        <li>Een kratten wijn en een paar flessen bugles</li>
                        <li>Ft knak galore</li>
                    </ul>
                </li>



            <li>2021-07-02
                    <ul>
                        <li>Middeleeuwse koperaanval tegen de slakken</li>
                        <li>Wijnrood op scharlakenrood</li>
                        <li>Astrid onttroont Bernard als Lego Koning(in) van de GSA</li>
                        <li>Mirco was buiten blijven liggen</li>
                        <li>Vlammetjes vlammend op vlammetjesschaal</li>
                        <li>Robert en Faustina zien ze niet vliegen</li>
                        <li>"Polaroid" 2.0</li>
                        <li>Er komt een nieuwe buurkat!</li>
                    </ul>
                </li>

            <li>2021-06-25
                    <ul>
                        <li>3 keer is scheepsrecht</li>
                        <li>Nachtelijk slakkenritueel</li>
                        <li>Als je maar lang genoeg buiten wacht komen er vanzelf buren</li>
                        <li> Glasintermezzo in Delft </li>
                    </ul>
                </li>

            <li>2021-06-11
                    <ul>
                        <li>3 keer is scheepsrecht</li>
                        <li>Michael verliest schoenen en telefoon</li>
                        <li>Als je slaapt, kun je niet appen</li>
                    </ul>
                </li>


                <li>2021-06-4
                    <ul>
                        <li>Saskia al vroeg dronken</li>
                        <li>Koude bitterballen</li>
                        <li>Arjen uit Scheveningen wil graag</li>
                        <li>Geen NL voetbal op de beamer als iedeeen er doorheen loopt te tetteren</li>
                        <li>Bewogen foto's reflecteren de staat van de aanwezigen</li>
                        <li>Kinderarbeid</li>
                    </ul>
                </li>


                <li>2021-05-21
                    <ul>
                        <li>Wim heeft een nieuwe matras</li>
                        <li>Vreemde vrouw op bankje</li>
                        <li>Niemand is weggewaaid</li>
                        <li>Er waren lil&#39; vlammetjes, maar geen vlammetjesplank.</li>
                        <li>Wouter ontmoet schaal en chips en neemt meteen mee naar huis</li>
                        <li>After bij Saskia&#39;s buren</li>
                    </ul>
                </li>

                <li>2021-04-30
                    <ul>
                        <li>Dubbeldip van Liesbeth op de bar</li>
                        <li>Liesbeth: "Bernard, zijn we nog vrienden?" Bernard: "Michael zijn er nog frikandellen?"</li>
                        <li>Is er nog mayonnaise</li>
                        <li>Huwelijkspreparaties</li>
                        <li>Bernard verjaagt de kuthoer van Wim met Supersoaker</li>
                    </ul>
                </li>

                <li>2021-04-27
                    <ul>
                        <li>Dressoir gehaast omhoog wegens cactus pickup</li>
                        <li>Cactus naald in tandvlees</li>
                        <li>Rekenmachines</li>
                        <li>Toch geen concurrentie voor M&B op de buurtborrel</li>
                        <li>Statensingel voedselbank, neem je eigen glas niet mee</li>
                    </ul>
                </li>

                <li>2021-04-23
                    <ul>
                        <li>Hanna bij de AA</li>
                        <li>Saskias flubber fluit</li>
                        <li>Astrid woont in de Greater Statensingel Area (GSA)</li>
                        <li>Hak de knak</li>
                        <li>Knuffel voor marjo</li>
                        <li>We hebben een bar, met dank aan Michael</li>
                    </ul>
                </li>

                <li>2021-04-16
                    <ul>
                        <li>Wij zijn Flips roedel</li>
                        <li>Zeer specialistisch aardewerk aangekocht voor populaire snack</li>
                        <li>Faboulicity</li>
                        <li>Invasie van buitenstraters</li>
                        <li>Mirco doet trucjes</li>
                        <li>Lelijke wijn van Henk</li>
                        <li>Pup Gaia van 8 weken Ridgeback</li>
                        <li>Visdeurbel</li>
                        <li>Vleermuizenborsten</li>
                        <li>Faunatunnels</li>
                        <li>Wouter, Liesbeth en de kids namen de proef op de som bij Chickling. Smaakte niet naar meer
                        </li>
                    </ul>
                </li>

                <li>2021-04-09
                    <ul>
                        <li>Bellyshots</li>
                        <li>Marjo drinkt het minst</li>
                        <li>Controleer regelmatig muizenval voor preventie familiemoord</li>
                        <li>Bas of Joost</li>
                        <li>Vele vlammetjes</li>
                        <li>Konijnenmoord #askmichael</li>
                        <li>Wouters last stop</li>
                        <li>Behoren we tot de roedel van Mars of Flip?</li>
                        <li>Zeer legale sneltesten aangeboden door Timo tegen passende financiële vergoeding (€ 7,16)
                        </li>
                    </ul>
                </li>

                <li>2021-04-02
                    <ul>
                        <li>Flapjes rosbief</li>
                        <li>Overdadig "overdatum" voedsel van Astrid</li>
                        <li>"Budget" wijn van Wouter</li>
                        <li>Goede pinot noir van de schoonvader van Remco</li>
                        <li>Liesbeth knuffelt met struik</li>
                        <li>Vlammetjes!</li>
                    </ul>
                </li>
                <li>2021-03-26
                    <ul>
                        <li>Liesbeth doet een unboxing van zielige planten</li>
                        <li>Shoppingadvies van de week: private shopping bij de Sissy-Boy</li>
                        <li>Groot drama met zeekomkommer</li>
                        <li>bestelfest met Thais en indiaas</li>
                        <li>Flips gone wild al dan niet door nieuwe look</li>
                    </ul>
                </li>
                <li>2021-03-19
                    <ul>
                        <li>Woks of wokken, discussie tussen Saskia en Robert</li>
                        <li>Ted is zijn mannelijkheid kwijt</li>
                        <li>Lars wilde appelmoes</li>
                        <li>Mirco ontdekt Grolsch</li>
                        <li>Marjolein heeft heldhaftig de nachtrust van de zeepaarden gered</li>
                        <li>Gorillas zitten op de Zwart janstraat</li>
                    </ul>
                </li>
                <li>2021-03-12
                    <ul>
                        <li>Erbarmelijke borrel omstandigheden met windkracht 6</li>
                        <li><a href="https://gorillas.io/nl">Boodschappen doen met gorillas</a></li>
                        <li>We moeten ons allemaal bij God verantwoorden voor ons slechte gedrag</li>
                        <li><a href="https://www.petitie24.nl/petitie/3604/cinerama-filmtheater-moet-blijven">cinerama
                            filmtheater moet blijven</a></li>
                        <li>Er zijn 4 niet werkende lampen</li>
                        <li><a href="https://www.funda.nl/koop/amsterdam/appartement-41244528-kinkerstraat-194-iv/">Pied-à-terre
                            kopen in Amsterdam</a></li>
                        <li>1 wijnglas kapot</li>
                        <li><a
                            href="https://www.nrc.nl/nieuws/2021/03/04/hamburgers-uit-een-blijdorpskeukentje-op-tweehoog-a4033926">Hamburgers
                            in blijdorp</a></li>
                    </ul>
                </li>
            </ul>
        </div>
    )

}

export default ShowNotes;
