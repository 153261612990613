import React from 'react';
import party from './m_b.gif';

function IsHetAlTijd(props) {


  // how much days untill partyStartDay?
  let deltaDay = props.partyStartDay - props.now.getDay()
  if (deltaDay < 0) {
    deltaDay = 7 + deltaDay;
  };


  let partyStartTime = new Date(props.now);
  let partyTimeEnd = new Date(partyStartTime);

  if (props.partyDate) {
    partyStartTime = new Date(props.partyDate);
    partyTimeEnd = new Date(partyStartTime);
    partyTimeEnd.setFullYear(2030)
  } else {
    partyStartTime.setDate(props.now.getDate() + deltaDay);
    partyStartTime.setHours(props.partyStartHour);
    partyStartTime.setMinutes(props.partyStartMinute);
    partyStartTime.setSeconds(0);
    partyTimeEnd = new Date(partyStartTime);
    partyTimeEnd.setHours(23);
    partyTimeEnd.setMinutes(59);
  }



  if (props.now.getTime() > partyStartTime.getTime() && props.now.getTime() < partyTimeEnd.getTime()) {
    return <Tijd textEvent={props.textEvent} />;
  }
  return <NogGeenTijd 
  partyTimeStart={partyStartTime} 
  now={props.now}
  loadingBarMax={props.loadingBarMax} />;
}


function Tijd(props) {
  return <React.Fragment>
    <h2>{props.textEvent}</h2>
    <img src={party} alt="Party" />
  </React.Fragment>;
}

function NogGeenTijd(props) {

  let timeUntillPartyInSeconds = Math.ceil((props.partyTimeStart.getTime() - props.now.getTime()) / 1000);
  let timeUntillPartyPercentage = (Math.min(Math.ceil((props.loadingBarMax - timeUntillPartyInSeconds) / props.loadingBarMax * 100), 100));


  const containerStyles = {
    height: '2em',
    width: '100%',
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    margin: 50
  }

  const fillerStyles = {
    height: '100%',
    width: `${timeUntillPartyPercentage}%`,
    backgroundColor: 'grey',
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: 'width 1s ease-in-out'
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1em',
    verticalAlign: 'middle'
  }

  return <React.Fragment>
    <div style={containerStyles} >
      <div style={fillerStyles} >
        <nobr style={labelStyles}> Nog {`${timeUntillPartyInSeconds}`} seconden </nobr>
      </div>
    </div>
  </React.Fragment>;
}


export default IsHetAlTijd;